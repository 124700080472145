import React from "react";


const DialUs = () => {
    return <>
    <div class="background">
      <h2 class="text-footer">
        Queries? Call us on +91 8527131889</h2>
    </div>    
    </>
}

export default DialUs;