import React, { useEffect } from "react";
import "./ImageSlider.css";
import balicomplex from "./images/bali-complex-hazaribagh-jharkhand.jpg";
import kathistage from "./images/kathi-nation-stage-decoration-hazaribagh-jharkhand.jpg";
import dininghall from "./images/luxurious-hall-mehfil-banquet-hazaribagh.jpg";
import partyhall from "./images/party-hall-mehfil-banquet-hazaribagh.jpg";
import videofile from "./images/mehfil-video-hazaribag.mp4"

const ImageSlider = () => {

    useEffect(() => {
        var counter = 1;
        setInterval(function(){
            if (document.getElementById('radio' + counter) != null) {
           document.getElementById('radio' + counter).checked = true;
        }
           counter++;
           if(counter > 4) {
               counter = 1
           }
        }, 3000);
    })

return (
    <div className="mainsection">

<div>
     <video  width="100%" height="650px" autoplay="" loop muted controls >
       <source src={videofile} type="video/mp4" />
          Your browser does not support the video tag.
     </video>
    </div>

    <div className="primarysection desktopview gpic3">
        <h1 className="homeTitle">Mehfil Banquet is the perfect place for your dream ceremonies.</h1>
         <p>
         To accommodate all of your demands, it is accompanied by three halls of varying sizes. Best suited for <strong> ring ceremony, wedding anniversary, receptions, birthday celebrations, freshers & farewell party, office meetings, etc. </strong>
         </p>
         <p>
            One should prefer Mehfil banquet hall instead of outdoor setup to avail the advantages of luxurious and modernized bathrooms or washrooms, dressing rooms for the bride and groom, changing room, well decorated stage, fully AC halls etc. 
         </p>
         <br/>
         <p>Supported by kathi nation, Hazaribagh, Jharkhand</p>
    </div>

    <div class="slider">
        <div class="slides">

            <input type="radio" name="radio-btn" id="radio1"/>
            <input type="radio" name="radio-btn" id="radio2"/>
            <input type="radio" name="radio-btn" id="radio3"/>
            <input type="radio" name="radio-btn" id="radio4"/>

            <div class="slide first">
                <img src={balicomplex} />
            </div>
            <div class="slide">
                <img src={kathistage} />
            </div>
            <div class="slide">
                <img src={dininghall} />
            </div>
            <div class="slide">
                <img src={partyhall} />
            </div>

            <div class="navigation-auto">
                <div class="auto-btn1"></div>
                <div class="auto-btn2"></div>
                <div class="auto-btn3"></div>
                <div class="auto-btn4"></div>
            </div>
 
        </div>

        <div class="navigation-manual">
            <label for="radio1" class="manual-btn"></label>
            <label for="radio2" class="manual-btn"></label>
            <label for="radio3" class="manual-btn"></label>
            <label for="radio4" class="manual-btn"></label>
        </div>
    </div>

    <div className="primarysection mobileview gpic3">
        <h1 className="homeTitle">Mehfil Banquet is the perfect place for your dream ceremonies.</h1>
         <p>
         To accommodate all of your demands, it is accompanied by three halls of varying sizes. Best suited for <strong> ring ceremony, wedding anniversary, receptions, birthday celebrations, freshers & farewell party, office meetings, etc. </strong>
         </p>
         <p>One should prefer Mehfil banquet hall instead of outdoor setup to avail the advantages of luxurious and modernized bathrooms or washrooms, dressing rooms for the bride and groom, changing room, well decorated stage, fully AC halls etc. </p>
         <br/>
         <p>Supported by kathi nation, Hazaribagh, Jharkhand</p>
    </div>

    </div>
    )
}

export default ImageSlider;