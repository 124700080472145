import React from "react";
import "./CubeView.css";
import bestpartyhall from "./images/best-party-mehfil-banquet-hall-jharkhand.jpg";
import dinninghall from "./images/dinning-hall-mehfil-banquet-hazaribag.jpg";
import droplight from "./images/drop-light-mehfil-banquet-hazaribagh.jpg"
import mainhall from "./images/main-hall-mehfil-banquet-hazaribagh.jpg";
import PageTitle from "./PageTitle";

const NightView = () => {

    return <>

        <PageTitle heading="Luxurious night view"  subheading="Your best choice when it comes to lighting systems, making your ceremony extra special."/>

        <img src={bestpartyhall} class="grid2"/>
		<img src={dinninghall} class="grid2 col-2"/>
		<img src={droplight} class="grid2"/>
		<img src={mainhall} class="grid2 col-2"  />

<div class="cubesectionTag" id="cubeSection">Cube View</div>
<div id="container_cube">
<div id="wb_Extension1">
<div id="Extension1">
<div class="cube">
   <div class="cube-face cube-face-front"></div>
   <div class="cube-face cube-face-back"></div>
   <div class="cube-face cube-face-left"></div>
   <div class="cube-face cube-face-right"></div>
   <div class="cube-face cube-face-top"></div>
   <div class="cube-face cube-face-bottom"></div>
</div>
</div></div>

</div>
    </>
}

export default NightView;