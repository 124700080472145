import React from "react";
import kns from "./images/kathi-nation-stage-decoration-hazaribagh-jharkhand.jpg";
import phmb from "./images/party-hall-mehfil-banquet-hazaribagh.jpg";
import bchzb from "./images/bali-complex-hazaribagh-jharkhand.jpg";
import sdmb from "./images/swan-decorated-mehfil-banquet.jpg";
import knrc from "./images/kathi-nation-ring-ceremony-decoration.jpg";
import lhmb from "./images/luxurious-hall-mehfil-banquet-hazaribagh.jpg";
import videofile from "./images/mehfil-video-hazaribag.mp4"
import parking from "./images/big-parking-mehfil-banquet.jpg"
import PageTitle from "./PageTitle";
const Gallary = () => {

    return <>
     
	  <PageTitle heading="Photographic story" id="gallery-mehfil-banquet-in-hazaribagh"  subheading="Narrates the greatest moments of mehfil banquet - Hazaribagh"/>

	<div className="section">
	
	

		<div class="paras gpic1">
			<h2 class="sectionTag text-big">Party Hall</h2>
			<div>
				<img src={phmb} class="serviceimg"/>
			</div>
			<p className="center">Extremely spacious banquel hall for 275 guests</p>
		</div>

		<div class="paras gpic2" id="course">
			<h2 class="sectionTag text-big">Dining</h2>
            <div>
            	<img src={lhmb} class="serviceimg"/>
            </div>
			<p className="center">Fully AC dining hall with gorgeous lighting</p>
		</div>

		<div class="paras gpic3">
			<h2 class="sectionTag text-big">Selfie Point</h2>
            <div>
            	<img src={sdmb} class="serviceimg"/>
            </div>
			<p className="center">Pleasantly decorated selfie point. Perfect place to capture your story </p>
		</div>
	</div>
	<div class="section">

		<div class="paras gpic4">
			<h2 class="sectionTag text-big">Parking</h2>
            <div><img class="serviceimg" src={parking}/></div>
			<p className="center">Huge parking space for 20 cars and 50 bikes</p>
		</div>

		<div class="paras gpic5">
			<h2 class="sectionTag text-big">Stage</h2>
            <div>
                <img src={kns} class="serviceimg"/>
            </div>
			<p className="center">Phenomenal stage decoration with warm led lights</p>
		</div>

		<div class="paras gpic6">
			<h2 class="sectionTag text-big">Mehfil</h2>
            <div><img class="serviceimg" src={bchzb}/></div>
			<p className="center">Luxurious front view of Mehfil Banquet</p>
		</div>
	</div>
    </>
}

export default Gallary;