import axios from "axios";
import React, { useState } from "react";
import PageTitle from "./PageTitle";
import { useEffect } from "react";

const Quote = () => {

    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [count, setCount] = useState('');
    const [showSubmitBtn, setShowSubmitBtn] = useState(true);

    const [nameError, setNameError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [countError, setCountError] = useState('');

    useEffect(() => {
      // 👇️ scroll to top on page load
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("submitted1")

        if(name == '') { setNameError('blank')}
        if(mobile == '') { setMobileError('blank')}
        if(email == '') { setEmailError('blank')}
        if(message == '') { setMessageError('blank')}
        if(count == '') { setCountError('blank')}

        if (name != '' 
           && mobile != ''
           && email != ''
           && message != ''
           && count != '') {

        axios.post('https://merwl6516c.execute-api.us-east-1.amazonaws.com/pm1/pmajaxrestapilamdases',
        {name,mobile,email,count,message})
        .then((resp) => {

         setShowSubmitBtn(false);
         setCount('');
         setEmail('');
         setMessage('');
         setName('');
         setMobile('');
        })
      }

    }

    return (
        <>
          <form onSubmit={(event) => handleSubmit(event)}>
          <PageTitle heading="Please complete the form to obtain a quote." subheading="" id="quoteid" />
<div>
            <div className="quoteFlex">
              <div className="width25 margin050"><span className="textfield">Name</span><br/>
                 <input className="width60"  type='text' name='name' value={name}
                   onChange={(e) => {
                    setName(e.target.value)
                    setNameError('')}}/>
                 {
                    nameError === 'blank' ? <div className = 'red'> Please enter name </div>:''
                 }
              </div>
              <div className="width25 margin050"><span className="textfield">Phone</span><br/>
                  <input className="width60" type='text' name='mobile' value={mobile}
                     onChange={(e) => {
                        setMobile(e.target.value.trim())
                        setMobileError('')
                     }}/>
                  {
                    mobileError === 'blank' ? <div className = 'red'> Please enter phone number </div>:''
                 }
              </div>
            </div>

            <div className="quoteFlex">
              <div className="width25 margin050"><span className="textfield">Email</span><br/>
                 <input className="width60"  type='text' name='email' value={email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                        setEmailError('')}}/>
                 {
                    emailError === 'blank' ? <div className = 'red'> Please enter email </div>:''
                 }
              </div>
              <div className="width25 margin050"><span className="textfield">Number of guests</span><br/>
                  <input className="width60" type='text' name='count' value={count}
                    onChange={(e) => {
                        setCount(e.target.value)
                        setCountError('')}}/>
                  {
                    countError === 'blank' ? <div className = 'red'> Please enter no. of guests </div>:''
                 }
              </div>
            </div>

            <div className="quoteFlex">

              <div className="width25 margin050"><span className="textfield">Message</span><br/>
                <textarea className="width60 messageheight" type='text' name='message' value={message}
                  onChange={(e) => {
                    setMessage(e.target.value)
                    setMessageError('')}}/>
                     {
                        messageError === 'blank' ? <div className = 'red'> Please enter message</div>:''
                     }
              </div>
            </div>

            <br/><br/>
            {
           showSubmitBtn ? <div className="submitbtn">
              <button id="fsave-btn" className="button green" >Submit</button>
            </div> : <div className="center colorgreen"> "Thanks for your inquiry. The Mehfil team will respond to you shortly." </div>
            }
            </div>
            </form>
        </>
    )
}

export default Quote;