import React from "react";
import Gallary from "../Gallary";
import NightView from "../NightView";
import Rate from "../Rate";
import DialUs from "../DialUs";
import ImageSlider from "../ImageSlider";

export const Home = () => {
  return (
    <div>
      <ImageSlider />
      <Gallary />
      <NightView />
      <DialUs />
      <br/>
      <Rate />
   
    </div>
  );
};
