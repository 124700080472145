import React from "react";
import PageTitle from "./PageTitle";

const Rate = () => {

    return <>
       	<div class="box docrec" id="rcost" >
         <PageTitle heading="Banquet Charges" id="charges-mehfil-banquet-in-hazaribagh" subheading="The least expensive banquet hall fees, with & without food "/>
	  
		<div class="sectionTag"></div>
        <table class="rcost">
        	<thead>
        		<td><strong>Item</strong></td>
        		<td><strong>Option 1</strong> <br/> <span class="inlineText">(Without Food)</span></td>
                <td><strong>Option 2</strong> <br/> <span class="inlineText">(With Food)</span></td>
    </thead>
  
    <tbody>
    <tr>
      <td>Food</td>
            <td>No</td>
      <td>Yes <span class="inlineText">(per plate cost)</span></td>

    </tr>
    <tr>
      <td>Hall <span class="inlineText">(3500 sqft)</span></td>
            <td>15000</td>
      <td>Free</td>
    </tr>
    <tr>
      <td>Maintenance <br/><span class="inlineText">(Generator, Sound)</span></td>
            <td>Free</td>
      <td>Free</td>
    </tr>
     <tr>
      <td>AC<span class="inlineText">(Electricity/DG set)</span></td>
      <td>5000*</td>
      <td>Free</td>
    </tr>
     <tr>
      <td>Parking</td>
      <td>Available</td>
      <td>Available</td>
    </tr>
     <tr>
      <td>2BHK Flat <span class="inlineText">(For Resting)</span></td>
      <td>Free</td>
      <td>Free</td>
    </tr>
    <tr>
      <td><strong>Total</strong></td>
      <td><strong>15k -20k</strong></td>
      <td><strong>--</strong></td>
    </tr>
    </tbody>
    </table>    
	</div>
    </>
}

export default Rate;