import React from "react";

const PageTitle = ({heading, subheading, id}) => {

    return <>
      <div className="w-75 mx-top20 center " id={id}>
                    <div className="fw-600 mb-lg-3 crs-details-heading mb-2">{heading}</div>
                    <p className="fw-400 lh-26 fs-16 label-color-1">{subheading}</p>
                </div>
    </>
}

export default PageTitle;