import './App.css';
import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router,  Link,  Route, Routes } from "react-router-dom";
import NavBar from './components/NavBar';
import { Home } from './components/Pages/Home';
import Collapsible from './components/Collapsible';
import DialUs from './components/DialUs';
import PageTitle from './components/PageTitle';
import Quote from './components/Quote';
import ContactUs from './components/ContactUs';

function App() {

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [btnState, setBtnState] = useState(true);

  const handleGetQuote = () => {
    setBtnState(false);
    console.log("button is false : " + btnState)
  }

  const faqs = [ 
    {
      "question": "How many people does the space accommodate?",
      'answer': "Mehfil banquet hall is very spacious with awesome ambience and can accomodate upto 275 guests at a time. "
    },
    {
      "question": "Mehfil banquet is best suited for?",
      'answer': "Mehfil banquet is affordable and best suited for ring ceremony, wedding aniversaries, freshers's and farewell party, office meetings etc. "
    },
    {
      "question": "Is this banquet having AC installed?",
      'answer': "YES, Mehfil banquet hall is best in hazaribag and is fully AC."
    },
    {
        "question": "Can we bring our own decorations?",
        'answer': "Mehfil banquet takes care of all the needs of the guests. "+
        'It provides good amount of decoration for every ceremony. If guest wants to bring their own decoration then YES they are allowed.'
    },
    {
      question: "Can we bring in our own alcohol, such as wine, beer and spirits?",
      answer: "Alcohol consumption in the banquet hall is stricitly prohibited."
    }, 
    {
      "question": "Are there any additional fees we should be aware of?",
      'answer': "There are no hidden charges."
  },
  {
    "question": "What's your deposit/cancellation/refund policy?",
    'answer': "100% refund policy if cancelled before 48 hours. Afterwards no refund. "
  },
  {
  "question": "How many hours does our rental cover?",
  'answer': "Rental covers upto 24hrs"
  },
  {
    "question": "Is there any rooms provided with the banquet hall?",
    'answer': "Yes, 2BHK flat is provided with the banquet hall."
  }
  ]

  return (
    <div className="App">
      <Router>
      <NavBar setBtnState= {setBtnState} btnState={btnState}/>

      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/quote-mehfil-banquet-in-hazaribag" element={<Quote />} />

      </Routes>
      <br/>
      <ContactUs/>
      <PageTitle heading="Frequently asked questions" subheading=""/>

      {
        faqs.map((faq) => {
          console.log(faq);
          return <Collapsible question={faq.question} answer={faq.answer}/>
        })
      }
{

     btnState == true ? <div className='feedback'>
        <Link to={'/quote-mehfil-banquet-in-hazaribag'}>
           <button id='mybutton' className='' onClick={handleGetQuote}>Get Quote</button>
        </Link>
      </div> : ""
      }

       <DialUs />
      </Router>
      
    </div>
  );
}

export default App;
